/* General App Container */
.app-container {
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.app-header {
  width: 100%;
  text-align: center;
  background-color: #343a40;
  color: white;
  padding: 20px;
  font-size: 2.5rem;
  font-weight: bold;
  letter-spacing: 1px;
}

.app-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 900px;
  margin-top: 20px;
}

/* Prompt Container */
.prompt-container {
  background-color: white;
  padding: 40px;  /* Increased padding for more space */
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  width: 100%;
  max-width: 600px;  /* Set max width for prompt container */
  text-align: center;
}

.prompt-container h2 {
  font-size: 1.8rem;  /* Make the heading slightly larger */
  color: #6c757d;
  margin-bottom: 25px;
}

.prompt-container p {
  font-size: 1.25rem;
  font-weight: 500;
  color: #212529;
  margin-top: 15px;
  margin-bottom: 30px;
}

/* Prompt Selector & Custom Prompt */
.prompt-selector,
.custom-prompt-input-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.prompt-selector select,
.custom-prompt-input {
    padding: 12px;
    font-size: 1rem;
    border: 1px solid #6c757d;
    border-radius: 5px;
    background-color: #f8f9fa;
    color: #343a40;
    width: 100%;
    max-width: 500px;  /* Ensure both elements have the same max-width */
    box-sizing: border-box;  /* Ensure padding and borders are included in the width calculation */
}

.custom-prompt-input-wrapper {
    flex-direction: column;
    align-items: center;
}

.custom-prompt-input {
    margin-bottom: 15px;
}


.prompt-container button {
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  padding: 12px 20px;  /* Increased button size */
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.prompt-container button:hover {
  background-color: #0056b3;
}

/* Timer Options */
.timer-options {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 1rem;
}

.timer-options label {
  margin-right: 10px;
  color: #343a40;
  font-weight: 500;
}

.timer-options select {
  padding: 10px;
  border: 1px solid #6c757d;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  background-color: #f8f9fa;
  color: #343a40;
}

/* Recording Buttons */
.recording-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.recording-buttons button {
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.recording-buttons button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.recording-buttons button:hover:not(:disabled) {
  background-color: #495057;
  color: white;
}

.recording-buttons button:nth-child(1) {
  background-color: #007bff;
  color: white;
}

.recording-buttons button:nth-child(2) {
  background-color: #dc3545;
  color: white;
}

/* Countdown Timer */
.countdown {
  font-size: 1.5rem;
  font-weight: bold;
  color: #343a40;
  margin-bottom: 30px;
}

/* Audio Playback */
.audio-playback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.audio-playback audio {
  margin-bottom: 10px;
}

.audio-playback button {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.audio-playback button:hover {
  background-color: #218838;
}

.transcription-result {
  margin-top: 20px;
  font-size: 1.1rem;
  color: #343a40;
}

/* Footer */
.app-footer {
  margin-top: auto;
  font-size: 0.9rem;
  color: #6c757d;
  text-align: center;
}

/* Error and Loading States */
.loading-spinner {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  color: #e63946;
  font-weight: bold;
  margin-top: 10px;
}

/* Login form styling */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0;
}

.login-form {
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.login-form h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.login-form button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #0056b3;
}

.login-form p {
  color: red;
  text-align: center;
  margin-top: 10px;
}

.toggle-button {
  background: none;
  color: #007BFF;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  width: 100%;
}

.toggle-button:hover {
  text-decoration: underline;
}

/* Styling for login button */
.login-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.login-button:hover {
  background-color: #0056b3;
}
