/* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal window */
.modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 100%; /* Ensure responsiveness */
    max-width: 400px; /* Constrain the modal width */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    box-sizing: border-box; /* Ensure padding and width work together */
}

/* Input group styling */
.input-group {
    margin-bottom: 15px;
}

.input-group input {
    width: 100%; /* Ensure the input field fits within the modal */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Button group styling */
.button-group {
    display: flex;
    justify-content: space-between;
}

.login-button, .cancel-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.login-button {
    background-color: #007bff;
    color: white;
}

.cancel-button {
    background-color: #f44336;
    color: white;
}

.login-button:hover {
    background-color: #0056b3;
}

.cancel-button:hover {
    background-color: #d32f2f;
}

/* New toggle auth styles */
.toggle-auth {
    text-align: center;
    margin-top: 15px;
}

.toggle-auth p {
    font-size: 0.9em;
}

.toggle-auth span {
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
}

.toggle-auth span:hover {
    color: #0056b3;
}
